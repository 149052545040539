
import { SuspenseFallback } from '@/components/SuspenseFallback/SuspenseFallback';
import { useAppDispatch } from '@/store';
import { toggleSidebar } from '@/store/reducers/sidebar/sidebar.actions';
import { selectOpened } from '@/store/reducers/sidebar/sidebar.selector';
import classNames from 'classnames';
import classnames from 'classnames';
import React, { Suspense } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Bottombar from '../containers/Bottombar/Bottombar.container';
import SideBar from "../containers/Sidebar/SideBar.container";
import './Profile.styles.scss';

type TProps = {};
type PropsFromRedux = ConnectedProps<typeof connector>;

const LProfileFunction: React.FC< TProps & PropsFromRedux> = ({
  sidebarOpened
}) => {

  const dispatch = useAppDispatch();

  return (
    <div className="l-profile">
      <SideBar />
      <div  className={classNames("backdrop",{"showed":sidebarOpened})}
            onClick={()=>{
              dispatch( toggleSidebar() )
            }}></div>
      <div className={classnames("profile-content")}>
        <Suspense fallback={<SuspenseFallback />}>
          <Outlet />
        </Suspense>
        <Bottombar />
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  sidebarOpened: selectOpened,
});


const connector = connect(mapStateToProps);
const LProfile = connector(LProfileFunction);

export default LProfile;
