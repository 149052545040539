import React,{ useState } from "react";
import { menus1 } from "@/config/sidebarMenus";
import SideMenu from "./SideMenu/SideMenu.element";
import PerfectScrollbar from "react-perfect-scrollbar";
import { InlineIcon } from "@iconify/react";
import {
  PowerSettingsNewRounded,
  ArrowBackRounded
} from "@mui/icons-material";
import { Slide, useMediaQuery } from "@mui/material";
import { toggleSidebar } from "@/store/reducers/sidebar/sidebar.actions";
import { selectOpened } from "@/store/reducers/sidebar/sidebar.selector";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import classNames from "classnames";
import { persistor, store, useAppDispatch } from "@/store";
import { useTheme } from '@mui/material/styles';
import "./SideBar.styles.scss";
import { routePaths } from "@/config";
import { useNavigate } from "react-router-dom";
import { getToken } from "@/store/reducers/member/member.actions";
import axios from "axios";


type PropsFromRedux = ConnectedProps<typeof connector>;

const CSideBarFunction: React.FC<PropsFromRedux> = ({
  sidebarOpened
}) => {

  const [indexMenuOpen, setIndexMenuOpen] = useState<number | null>(null);
  const dispatch =useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));


  const navigate = useNavigate();

  const logout = () => {
      navigate(routePaths.auth,{replace:true});
      store.dispatch({ type: 'CLEAR_STORE' });
      localStorage.clear();
      navigate(routePaths.auth, { replace: true });
      store.dispatch(getToken(''));
      persistor.purge();
      delete axios.defaults.headers.common["Authorization"];
  }

  return (
    <>
      <Slide direction="right" in={matches||sidebarOpened} mountOnEnter unmountOnExit>
        <div className={classNames("c-sidebar",{"opened":sidebarOpened})}>
          <div className="sidebar-top">
            <div className="sidebar-top__header-btn-container">
                <div  className="rounded-square"
                      onClick={()=>{
                        dispatch( toggleSidebar() )
                      }}>
                  <ArrowBackRounded sx={{color:'var(--ui-light)'}} />
                </div>

                <div  className="rounded-square"
                      onClick={logout}>
                  <PowerSettingsNewRounded sx={{color:'var(--ui-light)'}} />
                </div>
            </div>
            <div className="sidebar-top__logo-part">
                  <img
                    //style={{display:'none'}} // <- to hide before prod
                    src={require("@/assets/images/png/logo-white.png")}
                    alt="sidebar-logo"
                    className="sidebar-top__logo-part__logo"
                  />
            </div>

            <img
              src={require("@/assets/images/png/avatar.png")}
              alt="sidebar-logo"
              className="sidebar-top__avatar"
            />
            <p className="sidebar-top__username">Landry YAMB</p>
            <div className="sidebar-top__other-infos">
              <div className="sidebar-top__other-infos__infos-bloc">
                <InlineIcon icon="icon-park-outline:personal-privacy" />
                <span> Administrateur </span>
              </div>
            </div>
          </div>
          <div className="sidebar-top-for-lg">
            <div className="sidebar-top-for-lg__logo-part">
                <img
                //style={{display:'none'}} // <- to hide before prod
                  src={require("@/assets/images/png/logo-vase.png")}
                  alt="sidebar-logo"
                  className="sidebar-top-for-lg__logo-part__logo"
                />
                <img
                //style={{display:'none'}} // <- to hide before prod
                  src={require("@/assets/images/png/logo-resho.png")}
                  alt="sidebar-logo"
                  className="sidebar-top-for-lg__logo-part__logo"
                />
            </div>
            <p className="sidebar-top-for-lg__title">Menu</p>
          </div>
          <PerfectScrollbar className="sidebar-menu">
            {menus1.map((menu, idx) => (
              <SideMenu
                menu={menu}
                key={idx}
                myIndex={idx}
                indexMenuOpen={indexMenuOpen}
                setIndexMenuOpen={setIndexMenuOpen}
              />
            ))}
          </PerfectScrollbar>
        </div>
      </Slide>
      
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  sidebarOpened: selectOpened,
});


const connector = connect(mapStateToProps);
const CSideBar = connector(CSideBarFunction);

export default CSideBar;
