import React from "react";
import styled from "styled-components";
import MiniPiehart from "../Mini-pie-chart/MiniPieChart";
import { TDataForDonut } from "@/features/profile/types";
import { Skeleton } from "@mui/material";

type TKPICardProps = {
  title: string;
  value: number;
  ratio?: number;
  dataForDonut?: TDataForDonut;
  color?: string;
  loading: boolean;
};

const KPICard: React.FC<TKPICardProps> = ({
  loading,
  title,
  value,
  dataForDonut,
  color,
  ratio,
}) => {
  return (
    <KPICardContainer incomingColor={color}>
      {loading ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "20px",
            }}
          >
            <Skeleton
              variant="text"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="text"
              animation="pulse"
              width={"100%"}
              height={30}
            />
          </div>
        </>
      ) : (
        <>
          <span className="title"> {title} </span>
          <div className="value-part">
            <span className="value"> {value} </span>
            {dataForDonut !== undefined && (
              <div className="mini-pie">
                <MiniPiehart
                  value1={dataForDonut.value1}
                  value2={dataForDonut.value2}
                  value3={dataForDonut.value3}
                  value4={dataForDonut.value4}
                  color1={dataForDonut.color1}
                  color2={dataForDonut.color2}
                  color3={dataForDonut.color3}
                  color4={dataForDonut.color4}
                  height={30}
                  width={30}
                  interRadius={5}
                />
              </div>
            )}
            {ratio !== undefined && <span className="ratio"> {ratio}% </span>}
          </div>
        </>
      )}
    </KPICardContainer>
  );
};

const KPICardContainer = styled.div<{ incomingColor: string | undefined }>`
  height: fit-content;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 30px #eeecec;
  border-left: 5px solid
    ${({ incomingColor }) => {
      if (incomingColor) {
        return incomingColor;
      } else {
        return "var(--ui-medium)";
      }
    }};
  padding: 30px 20px;
  gap: 5px;
  width: calc(100% / 5 - 20px);
  flex: 1;

  // X-Large devices (large desktops, less than 1400px)
  @media (max-width: 1399.98px) {
    width: calc(100% / 5 - 20px);
    flex: unset;
  }

  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
    width: calc(100% / 3 - 20px);
    flex: unset;
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    width: calc(100% / 3 - 20px);
    flex: unset;
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    width: calc(100% / 2 - 20px);
    flex: unset;
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    width: 100%;
    flex: unset;
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    width: 100%;
    flex: unset;
  }

  .title {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--ui-gray);
  }

  .value-part {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;

    .value {
      font-size: 1.7rem;
      font-weight: 600;
      color: ${({ incomingColor }) => {
        if (incomingColor) {
          return incomingColor;
        } else {
          return "var(--ui-medium)";
        }
      }};
    }

    .ratio {
      margin-left: auto;
      font-size: 1.2rem;
      font-weight: 400;
      color: ${({ incomingColor }) => {
        if (incomingColor) {
          return incomingColor;
        } else {
          return "var(--ui-medium)";
        }
      }};
    }

    .mini-pie {
      margin-left: auto;
      height: 30px;
      width: 30px;
    }
  }
`;

export default KPICard;
