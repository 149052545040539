import LContainer from '@/components/Layouts/Container/Container.layout';
import React from 'react';
import { Outlet } from 'react-router-dom';
import './Authentication.styles.scss';

const LAuthentication: React.FC = () => {
  return (
    <div className="authentication-layout-container">
        <LContainer>
            <div className="form-container">
              <Outlet />
              <small> Copyright © tous droits réservés - 2023 | RESHO 121 - Design and development by <a href='https://everest-consulting.org/' rel="noreferrer" target='_blank' style={{textDecoration:'none', color: '#ffffff', cursor:'pointer'}}> Everest Consulting </a> </small>
            </div>
        </LContainer>
    </div>
  )
}

export default LAuthentication;
