import React from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, CartesianGrid, Tooltip, YAxis } from "recharts";
import './MainColumnChart.styles.scss';

type TMainColumnChart = {
    dataCharts: {
            data_date:string,
            adhesions:number,
          }[]
}
const MainColumnChart: React.FC<TMainColumnChart> = ({
    dataCharts
}) => {


    return (
        <div className="main-column-container">

            <div className="chart-part">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={dataCharts}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 7" />
                        <XAxis 
                            dataKey="data_date"
                            axisLine={false} />
                        <YAxis 
                            width={40}
                            tickSize={0}
                            mirror={false}
                            axisLine={false}
                            tickMargin={10}
                        />
                        <Tooltip />
                        <Bar dataKey={ "adhesions"} radius={[5,5,0,0]} barSize={10} opacity={1} fill={ '#0062FF' } />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default MainColumnChart