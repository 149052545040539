import React, { useState } from "react";
import { routePaths } from "@/config";
import { TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./ForgottenPasswordForm.styles.scss";
import { Toastify } from "@/utils/toast";
import { TForgotPassword } from "../../types";
import { store } from "@/store";
import { updateEmail } from "@/store/reducers/temp_data/temp_data.actions";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiForgotPasswordMember } from "../../api/auth.api";
import { LoadingButton } from "@mui/lab";

const ForgottenPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Entrez une adresse mail valide")
      .required(" l'email est obligatoire "),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      forgottenPasswordSubmit(values);
    },
  });

  const forgottenPasswordSubmit = (data: TForgotPassword) => {
    setLoading(true);
    apiForgotPasswordMember(data)
      .then((response: any) => {
        setLoading(false);
        console.log(response);

        navigate(routePaths.authChildren.resetPasswordAbsolute, {
          replace: true,
        });
        store.dispatch(updateEmail(data.email));

        Toastify(
          `Code OTP envoyé. Consulter votre boite email`,
          "success",
          5000
        );
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="forgotten-password-container">
      <div className="header-part">
        <h1 className="title">MOT DE PASSE OUBLIÉ ?</h1>
        <p className="subtitle"> SAISIR VORE ADDRESSE E-MAIL </p>
      </div>
      <form className="form-part" onSubmit={formik.handleSubmit}>
        <TextField
          placeholder="Votre adresse email"
          variant="outlined"
          label="Email"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          type="email"
        />
        <LoadingButton
          loading={loading}
          loadingPosition="center"
          variant="contained"
          color="tertiary"
          sx={{ marginTop: "30px" }}
          type="submit"
        >
          ENVOYER LA DEMANDE DE RÉINITIALISATION
        </LoadingButton>
      </form>
      <div className="footer-part">
        <Link
          className="link-primary"
          to={routePaths.authChildren.signInAbsolute}
        >
          {" "}
          Retourner sur la page de connexion
        </Link>
      </div>
    </div>
  );
};

export default ForgottenPasswordForm;
