import { TUserConnected } from "@/features/profile/types";
import memberTypes from "./member.types";
interface IGetUserConnected{
  type: memberTypes.GET_USER_CONNECTED;
  payload: TUserConnected;
}

interface IGetToken{
  type: memberTypes.GET_TOKEN;
  payload: string;
}


export type MemberAction =  IGetUserConnected | 
                            IGetToken;


export const getUserConnected = (user:TUserConnected) => ({
  type: memberTypes.GET_USER_CONNECTED,
  payload: user
});

export const getToken = (token:string) => ({
  type: memberTypes.GET_TOKEN,
  payload: token
});

