
import temp_dataTypes from "./temp_data.types";

interface IUpdateEmail{
  type: temp_dataTypes.UPDATE_EMAIL;
  payload: string;
}



export type TempDataAction = IUpdateEmail;


export const updateEmail = ( email : string ) => ({
  type: temp_dataTypes.UPDATE_EMAIL,
  payload: email
});
