import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Dashboard.content.style.scss";
import KPICard from "@/components/KPICard/KPICard";
import DashboardChartCard from "@/components/Dashboard-chart-card/DashboardChartCard";
import DashboardListCard from "@/components/Dashboard-list-card/DashboardListCard";
import { TAdherent, TDataForDonut } from "../../types";
import { Toastify } from "@/utils/toast";
import * as moment from "moment";
import "moment/locale/fr";
import { apiListAdherent, apiStatDashboard } from "../../api/profile.api";

const CtDashboard: React.FC = () => {
  const [dataForDonutAdherent, setDataForDonutAdherent] =
    useState<TDataForDonut>({
      value1: 0,
      value2: 0,
      value3: 0,
      value4: 0,
      color1: "#006C69",
      color2: "#872D2D",
      color3: "#F5B544",
      color4: "#ff1744",
    });

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [nbrTotal, setNbrTotal] = useState(0);
  const [adherentsList, setAdherentsList] = useState<TAdherent[]>([]);
  const [dataForChart, setDataForChart] = React.useState<
    { data_date: string; adhesions: number }[]
  >([]);

  const getAllAdherents = () => {
    setLoading(true);
    apiListAdherent(1, 4)
      .then((response: any) => {
        let tempNbrAccepte = response.data.stats.accepte;
        let tempNbrRefuse = response.data.stats.refuse;
        let tempNbrAttente = response.data.stats.en_attente;
        let tempNbrTotal = response.data.total;

        setDataForDonutAdherent({
          value1: tempNbrAccepte,
          value2: tempNbrRefuse,
          value3: tempNbrAttente,
          value4: 0,
          color1: "#006C69",
          color2: "#872D2D",
          color3: "#F5B544",
          color4: "#ff1744",
        });
        setNbrTotal(tempNbrTotal);
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getStatDashboard = () => {
    setLoading1(true);
    apiStatDashboard()
      .then((response: any) => {
        let data_last_added = response.data.last_add;
        let data_for_chart = response.data.graph_stats.map((elt: any) => {
          return {
            data_date: moment.default(new Date(elt.date)).format(" Do MMM "),
            adhesions: elt.total,
          };
        });

        setAdherentsList(data_last_added as TAdherent[]);
        setDataForChart(
          data_for_chart as { data_date: string; adhesions: number }[]
        );
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  useEffect(() => {
    getAllAdherents();
    getStatDashboard();
  }, []);

  return (
    <>
      <div className="ct-dashboard">
        <Box>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <div className="kpi-part">
                <KPICard
                  loading={loading}
                  title="Total Adhésions"
                  value={nbrTotal}
                  dataForDonut={dataForDonutAdherent}
                />
                <KPICard
                  loading={loading}
                  title="Adhésion(s) acceptée(s)"
                  value={dataForDonutAdherent.value1}
                  ratio={
                    Math.round((dataForDonutAdherent.value1 / nbrTotal) * 100) |
                    0
                  }
                  color={dataForDonutAdherent.color1}
                />
                <KPICard
                  loading={loading}
                  title="Adhésion(s) refusée(s)"
                  value={dataForDonutAdherent.value2}
                  ratio={
                    Math.round((dataForDonutAdherent.value2 / nbrTotal) * 100) |
                    0
                  }
                  color={dataForDonutAdherent.color2}
                />
                <KPICard
                  loading={loading}
                  title="Adhésion(s) en Attente"
                  value={dataForDonutAdherent.value3}
                  ratio={
                    Math.round((dataForDonutAdherent.value3 / nbrTotal) * 100) |
                    0
                  }
                  color={dataForDonutAdherent.color3}
                />
              </div>
            </Grid>
            <Grid item xs={12} xl={9}>
              <DashboardChartCard
                dataForChart={dataForChart}
                loading={loading1}
              />
            </Grid>
            <Grid item xs={12} xl={3}>
              <DashboardListCard
                adherentsList={adherentsList}
                loading={loading1}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default CtDashboard;
