import React from 'react';
import { Slide, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { InlineIcon } from "@iconify/react";
import './Bottombar.styles.scss';
import { routePaths } from '@/config';
import { useNavigate } from 'react-router-dom';

const  Bottombar:React.FC = () =>  {
    
    const theme = useTheme();
    const matches_down_lg = useMediaQuery(theme.breakpoints.down('lg'));
    const matches_down_md = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [value, setValue] = React.useState(routePaths.profileChildren.homeAbsolute);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const goTo = (route:string) => {
        navigate(route,{replace:true});
    }

    React.useEffect(() => {
        setValue(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])
    

  return (
    <Slide direction="up" in={matches_down_lg} mountOnEnter unmountOnExit>
        <div className="c-bottombar">
        <BottomNavigation 
                className="mui-bottom-navigation" 
                value={value} onChange={handleChange}>
                <BottomNavigationAction
                    label={ matches_down_md? null : "Tableau de bord" }
                    value={routePaths.profileChildren.dashboardAbsolute}
                    onClick={()=>{
                        goTo(routePaths.profileChildren.dashboardAbsolute)
                    }}
                    icon={ 
                        <InlineIcon icon="material-symbols:donut-small" /> 
                    }
                />
                <BottomNavigationAction
                    label={ matches_down_md? null : "Membres" }
                    value={routePaths.profileChildren.membersAbsolute}
                    onClick={()=>{
                        goTo(routePaths.profileChildren.membersAbsolute)
                    }}
                    icon={ 
                        <InlineIcon icon="fluent:people-team-20-filled" /> 
                    }
                />
                <BottomNavigationAction
                    label={ matches_down_md? null : "Utilisateurs" }
                    value={routePaths.profileChildren.usersAbsolute}
                    onClick={()=>{
                        goTo(routePaths.profileChildren.usersAbsolute)
                    }}
                    icon={ 
                        <InlineIcon icon="mdi:users" /> 
                    }
                />
        </BottomNavigation>
        </div>
    </Slide>
  )
}

export default Bottombar;