import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

type TMiniPieChart = {
    value1?: number,
    value2?: number,
    value3?: number,
    value4?: number,
    color1?: string,
    color2?: string,
    color3?: string,
    color4?: string,
    height?:number,
    width?:number,
    interRadius?:number,
}
const MiniPiehart: React.FC<TMiniPieChart> = ({
    value1,
    value2,
    value3,
    value4,
    color1,
    color2,
    color3,
    color4,
    height=50,
    width=50,
    interRadius=10,
}) => {




    const [dataCharts , setDataCharts] = useState<{name: string, value: number }[]>([]);
    const [colorCharts , setColorCharts] = useState<string[]>([]);

    useEffect(() => {

        let data:{name: string, value: number }[] = [];

        value1 && data.push({ name: '', value: value1 });
        value2 && data.push({ name: '', value: value2 });
        value3 && data.push({ name: '', value: value3 });
        value4 && data.push({ name: '', value: value4 });

        let colorList = [];

        color1 && colorList.push(color1);
        color2 && colorList.push(color2);
        color3 && colorList.push(color3);
        color4 && colorList.push(color4);


        setDataCharts([...data]);
        setColorCharts([...colorList]);

    // eslint-disable-next-line    
    }, [value1, value2, value3, value4]);



    return (

        <PieChart width={width} height={height}>
            <Pie data={dataCharts}
                 innerRadius={ (width/2 - interRadius )}
                 outerRadius={ width/2 }
                 paddingAngle={5}
                 dataKey="value">
                {dataCharts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colorCharts[index % colorCharts.length]} />
                ))}
            </Pie>
        </PieChart>
    )
}

export default MiniPiehart