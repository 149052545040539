import Axios from "axios";
import { API_URL } from "../config";
import { persistor, store } from "@/store";
import { getToken } from "@/store/reducers/member/member.actions";

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // const message = error.response?.data?.message || error.message;
    console.log(error);
    if (error.response.data.code === "Déconnexion") {
      store.dispatch({ type: "CLEAR_STORE" });
      localStorage.clear();
      // navigate(routePaths.auth, { replace: true });
      store.dispatch(getToken(""));
      persistor.purge();
      delete axios.defaults.headers.common["Authorization"];
    }

    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  const token = store.getState().member.token;
  config.headers = {
    Authorization: token ? `Bearer ${token}` : "",
  };
  return config;
});
