import { TUserConnected } from "@/features/profile/types";
import { MemberAction } from "./member.actions";
import memberTypes from "./member.types";

interface IMemberReducerState {
  user_connected: TUserConnected | null;
  token: string;
}

const INITIAL_STATE = {
  user_connected: null,
  token: '',
};

const memberReducer = (
  state: IMemberReducerState = INITIAL_STATE,
  action: MemberAction
): IMemberReducerState => {
  switch (action.type) {
    case memberTypes.GET_USER_CONNECTED:
      return {
        ...state,
        user_connected: action.payload
      };

    case memberTypes.GET_TOKEN:
      return {
        ...state,
        token: action.payload
      };

    default:
      return state;
  }
};

export default memberReducer;
