import { InlineIcon } from "@iconify/react";
import { Snackbar } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";

type TInfosDisplayer = {
  label?: string;
  text?: string;
  icon?: string;
  modified_text?: string;
};

const InfosDisplayer: React.FC<TInfosDisplayer> = ({
  label = "",
  text = "",
  icon = "",
  modified_text = "",
}) => {
  const [openSnack, setOpenSnack] = useState(false);

  const copyShellExec = () => {
    navigator.clipboard.writeText(text).then(() => {
      setOpenSnack(true);
    });
  };

  return (
    <>
      <DisplayContainer title={text}>
        <div className="dc_title">
          <InlineIcon icon={icon} />
          <span>{label}</span>
        </div>
        <p>{text}</p>
        {modified_text !== "" && <p className="edt-text">{modified_text}</p>}

        <InlineIcon
          className="cpy-icon"
          icon="material-symbols:copy-all-rounded"
          onClick={copyShellExec}
        />
      </DisplayContainer>

      <Snackbar
        open={openSnack}
        autoHideDuration={1000}
        onClose={() => {
          setOpenSnack(false);
        }}
        message="Copié!!"
      />
    </>
  );
};

const DisplayContainer = styled.div<{}>`
  width: "100%";
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: var(--ui-light);
  border-radius: 3px;
  align-self: stretch;
  position: relative;
  .dc_title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--ui-dark);
  }
  p {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--ui-medium);

    &.edt-text{
      border-top: 1px dashed #dedede;
      padding-top: 5px;
      padding-left: 5px;
      margin-top: 5px;
        font-weight: 600;
        color: var(--ui-secondary);
    }
  }
  &:hover {
    .cpy-icon {
      display: block;
    }
  }
  .cpy-icon {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    color: var(--ui-gray);
    cursor: pointer;

    &:hover {
      color: var(--ui-dark);
      transform: scale(1.1) translateY(-50%);
    }
  }
`;

export default InfosDisplayer;
