export const routePaths = {
  root: "/",
  auth: "/auth",
  authChildren: {
    signIn : "/sign-in",
    signInAbsolute : "/auth/sign-in",
    signUp : "/sign-up",
    signUpAbsolute : "/auth/sign-up",
    forgottenPassword : "/forgot-password",
    forgottenPasswordAbsolute : "/auth/forgot-password",
    resetPassword : "/reset-password",
    resetPasswordAbsolute : "/auth/reset-password",
  },
  profile: "/profile",
  profileChildren: {
    dashboard: "/dashboard",
    dashboardAbsolute: "/profile/dashboard",
    members: "/members",
    membersAbsolute: "/profile/members",
    users: "/users",
    usersAbsolute: "/profile/users",

    home:"/home",
    homeAbsolute: "/profile/home",
    favorite:"/favorite",
    favoriteAbsolute: "/profile/favorite",
    exchange:"/exchange",
    exchangeAbsolute: "/profile/exchange",
    search:"/search",
    searchAbsolute: "/profile/search",
    myAccount:"/my-account",
    myAccountAbsolute: "/profile/my-account",
    notification:"/notification",
    notificationAbsolute:"/profile/notification",
    subscription:"/subscription",
    subscriptionAbsolute:"/profile/subscription",
    subscriptionChildren: {
      desactivateSubscription:"/desactivate", 
      desactivateSubscriptionAbsolute:"/profile/subscription/desactivate",
      deleteAccount:"/delete", 
      deleteAccountAbsolute:"/profile/subscription/delete",
    },
  },
};
