import React from 'react';
import Header from '../containers/Header/Header.container';
import CtMembres from '../pages-content/Membres-content/Membres.content';

const Membres:React.FC = () => {
  return (
    <>
      <Header title="Gestion des membres"/>
      <CtMembres />
    </>
  )
}

export default Membres;