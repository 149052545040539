import React from 'react';
import Header from '../containers/Header/Header.container';
import CtUtilisateurs from '../pages-content/Utilisateurs-content/Utilisateurs.content';

const Utilisateurs:React.FC = () => {
  return (
    <>
      <Header title="Gestion des adhérents"/>
      <CtUtilisateurs />
    </>
  )
}

export default Utilisateurs;