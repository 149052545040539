import React, { ReactNode } from 'react';
import Modal from '@mui/material/Modal';
import './SideModal.styles.scss';
import { IconButton, Slide } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { HighlightOffRounded } from '@mui/icons-material';



type TModalProps = {
    open: boolean,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined , 
    closeClick: () => void,
}

const SideModal:React.FC<TModalProps & {children:ReactNode}> = ({
    open,
    onClose,
    closeClick,
    children
}) => {




  return (
    <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <Slide in={open} 
               direction="left">
          <div className='side-modal-container'>
                <IconButton className='close-icon'
                        size="large" 
                        color='inherit'
                        onClick={closeClick}>
                    <HighlightOffRounded />
                </IconButton>
                <PerfectScrollbar className='scroll-modal-content'>
                    {children}
                </PerfectScrollbar>
          </div>
        </Slide>
      </Modal>
  )
}

export default SideModal;