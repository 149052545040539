import React, { createContext, useCallback, useEffect, useState } from "react";
import "./Membres.style.scss";
import { InlineIcon } from "@iconify/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import TableMembreManager from "@/components/TableMembreManager/TableMembreManager";
import SideModal from "@/components/SideModal/SideModal";
import MemberForm from "../../forms/MemberForm/MemberForm";
import { apiListMembres } from "../../api/profile.api";
import { Toastify } from "@/utils/toast";
import { TUserConnected } from "../../types";
import { selectUserConnected } from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { routePaths } from "@/config";
import { store } from "@/store";
import { getToken } from "@/store/reducers/member/member.actions";
import { useNavigate } from "react-router-dom";
import { updateEmail } from "@/store/reducers/temp_data/temp_data.actions";

type PropsFromRedux = ConnectedProps<typeof connector>;

export const MemberContext = createContext<{
  uiNewMemberList: (data?: any) => void;
  uiUpdateMemberList: (data?: any) => void;
}>({
  uiNewMemberList: (data?: any) => {},
  uiUpdateMemberList: (data?: any) => {},
});

const CtMembresFct: React.FC<PropsFromRedux> = ({ userConnected }) => {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const [reqPage, setReqPAge] = useState(1);
  const [reqLimit, setLimit] = useState("3");
  const [totalLigne, setTotalLigne] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [editedMember, setEditedMember] = useState<TUserConnected | null>(null);

  const [membersList, setMembersList] = useState<TUserConnected[]>([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDialogExit, setOpenDialogExit] = useState(false);
  const handleClickOpenDialogExit = () => setOpenDialogExit(true);
  const handleCloseDialogExit = () => setOpenDialogExit(false);

  const navigate = useNavigate();

  const getAllMembres = useCallback(() => {
    setLoading(true);
    apiListMembres(reqPage, parseInt(reqLimit), searchText)
      .then((response: any) => {
        let data = response.data.member;
        let tempTotalLigne = response.data.total;
        let tempTotalPage = response.data.page;
        setMembersList(data as TUserConnected[]);
        setTotalLigne(tempTotalLigne);
        setTotalPage(tempTotalPage);
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reqPage, reqLimit, searchText]);

  useEffect(() => {
    getAllMembres();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllMembres]);

  const uiNewMemberList = (newRecord: TUserConnected) => {
    let tempAdherentsList = [...membersList, { ...newRecord }];
    setMembersList([...tempAdherentsList]);
    Toastify("Ajout effectué", "success", 2000);
  };

  const uiUpdateMemberList = (editedRecord: TUserConnected) => {
    let tempAdherentsList = [...membersList];
    let selectedIndex = tempAdherentsList.findIndex(
      (elt) => elt.id === editedRecord.id
    );
    tempAdherentsList[selectedIndex] = { ...editedRecord };
    setMembersList([...tempAdherentsList]);

    Toastify("Modification effectuée", "success", 2000);

    if (
      userConnected?.id === editedRecord.id &&
      userConnected?.role.libelle !== editedRecord.role.libelle
    ) {
      setEditedMember(editedRecord);
      handleClickOpenDialogExit();
    }
  };

  const logout = () => {
    navigate(routePaths.auth, { replace: true });
    store.dispatch(getToken(""));
    store.dispatch(updateEmail(userConnected?.email || ""));
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setReqPAge(page);
  };

  return (
    <MemberContext.Provider
      value={{
        uiNewMemberList: uiNewMemberList,
        uiUpdateMemberList: uiUpdateMemberList,
      }}
    >
      <>
        <div className="p-members-container">
          {(userConnected as TUserConnected).role.libelle ===
            "Administrateur" && (
            <div className="tool-part">
              <Button
                className="tool-btn"
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpen();
                }}
                startIcon={
                  <InlineIcon icon="material-symbols:export-notes-outline-rounded" />
                }
              >
                Ajouter un membre
              </Button>
            </div>
          )}

          <div className="table-part">
            <TableMembreManager
              main_title="Liste des membres"
              setSearchText={setSearchText}
              data={membersList}
              loading={loading}
            />
            <div className="table-part__table-footer">
              <span className="table-part__table-footer__footer-indicator">
                Affichage de <b>{parseInt(reqLimit) * (reqPage - 1) + 1}</b> à{" "}
                <b>{parseInt(reqLimit) * (reqPage - 1) + membersList.length}</b>{" "}
                sur <b>{totalLigne}</b> résultats
              </span>

              <FormControl
                sx={{ m: 1, minWidth: 100, marginLeft: "10px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">Lignes</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={reqLimit}
                  label="Lignes"
                  onChange={(e) => {
                    setLimit(e.target.value);
                  }}
                >
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </FormControl>

              <Pagination
                className="table-part__table-footer__pagination"
                count={totalPage}
                onChange={handlePaginationChange}
                shape="rounded"
              />
            </div>
          </div>
        </div>
        <SideModal open={open} onClose={handleClose} closeClick={handleClose}>
          <>
            <MemberForm closeClick={handleClose} />
          </>
        </SideModal>
        <Dialog
          open={openDialogExit}
          onClose={handleCloseDialogExit}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Déconnexion automatique"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vous venez de modifier votre rôle, <br />
              Vous êtes passé de <b> {userConnected?.role.libelle} </b> à{" "}
              <b> {editedMember?.role.libelle} </b>. <br />
              Pour bénéficier de vos nouveaux droits, veuillez vous déconnecter.
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={handleCloseDialogExit}>
              Annuler
            </Button>
            <Button color="primary" onClick={logout} autoFocus>
              Se déconnecter
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </MemberContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps);
const CtMembres = connector(CtMembresFct);

export default CtMembres;
