import { routePaths } from "@/config";
import { Navigate, Route, Routes } from "react-router-dom";
import LProfile from "../layouts/Profile.layout";
import Dashboard from "../pages/Dashboard.page";
import Membres from "../pages/Membres.page";
import Utilisateurs from "../pages/Utilisateurs.page";

export const ProfileRoutes:React.FC = () => {
    return (
      <Routes>
          <Route path="" element={<LProfile />}>
              <Route index element={ <Navigate to={`${routePaths.profileChildren.dashboardAbsolute}`} />} />
              <Route path={routePaths.profileChildren.dashboard} element={ <Dashboard />} />
              <Route path={routePaths.profileChildren.members} element={ <Membres />} />
              <Route path={routePaths.profileChildren.users} element={ <Utilisateurs />} />
          </Route>
      </Routes>
    );
};