import { InlineIcon } from "@iconify/react";
import { Grid, Skeleton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./AdherentForm.style.scss";
import { Toastify } from "@/utils/toast";
import {
  apiAcceptAdherent,
  apiAdherentProfileValidate,
  apiGetAdherentEditedChange,
  apiRefuseAdherent,
} from "../../api/profile.api";
import { LoadingButton } from "@mui/lab";
import { UtilisateurContext } from "../../pages-content/Utilisateurs-content/Utilisateurs.content";
import InfosDisplayer from "@/components/InfosDisplayer/InfosDisplayer";
import { TAdherent } from "../../types";

type TAdherentForm = {
  selectedAdherent: TAdherent;
  closeClick: () => void;
};

const AdherentForm: React.FC<TAdherentForm> = ({
  selectedAdherent,
  closeClick,
}) => {
  const { uiUpdateAdherentlist } = useContext(UtilisateurContext);
  const [newValueAdherent, setNewValueAdherent] = useState<TAdherent | null>(
    null
  );

  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingRefuse, setLoadingRefuse] = useState(false);

  const [loading, setLoading] = useState(false);

  const acceptAdherent = () => {
    setLoadingAccept(true);
    apiAcceptAdherent(selectedAdherent.id || "")
      .then((response: any) => {
        // Code goes here
        let dataToSend = response.data as TAdherent;
        uiUpdateAdherentlist(dataToSend);
        closeClick();
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoadingAccept(false);
      });
  };

  const refuseAdherent = () => {
    setLoadingRefuse(true);
    apiRefuseAdherent(selectedAdherent.id || "")
      .then((response: any) => {
        // Code goes here
        let dataToSend = response.data as TAdherent;
        uiUpdateAdherentlist(dataToSend);
        closeClick();
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoadingRefuse(false);
      });
  };

  const acceptAdherentModif = () => {
    setLoadingAccept(true);
    apiAdherentProfileValidate(selectedAdherent.id || "", true)
      .then((response: any) => {
        // Code goes here
        let dataToSend = response.data as TAdherent;
        uiUpdateAdherentlist(dataToSend);
        closeClick();
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoadingAccept(false);
      });
  };

  const refuseAdherentModif = () => {
    setLoadingRefuse(true);
    apiAdherentProfileValidate(selectedAdherent.id || "", false)
      .then((response: any) => {
        // Code goes here
        let dataToSend = response.data as TAdherent;
        uiUpdateAdherentlist(dataToSend);
        closeClick();
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoadingRefuse(false);
      });
  };

  useEffect(() => {
    if (selectedAdherent.isModif) {
      setLoading(true);
      apiGetAdherentEditedChange(selectedAdherent.id)
        .then((response: any) => {
          // Code goes here
          let dataToSend = response.data.new_user as TAdherent;
          setNewValueAdherent(dataToSend);
        })
        .catch((error) => {
          Toastify(
            `${error?.response?.data?.message || "Une erreur est survenue"}`,
            "error",
            5000
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="adherent-form">
      <span className="title"> Détail de l'adhérent </span>
      {loading ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "20px",
            }}
          >
            <Skeleton
              variant="text"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="text"
              animation="pulse"
              width={"100%"}
              height={30}
            />
          </div>
        </>
      ) : (
        <>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={12} lg={12} xl={6}>
              <InfosDisplayer
                label="Nom(s)"
                text={selectedAdherent.nom}
                icon="mdi:user"
                modified_text={
                  newValueAdherent?.nom !== selectedAdherent.nom
                    ? newValueAdherent?.nom
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={6}>
              <InfosDisplayer
                label="Prénom(s)"
                text={selectedAdherent.prenoms}
                icon="mdi:user"
                modified_text={
                  newValueAdherent?.prenoms !== selectedAdherent.prenoms
                    ? newValueAdherent?.prenoms
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={6}>
              <InfosDisplayer
                label="E-mail"
                text={selectedAdherent.email}
                icon="material-symbols:mail"
                modified_text={
                  newValueAdherent?.email !== selectedAdherent.email
                    ? newValueAdherent?.email
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={6}>
              <InfosDisplayer
                label="Téléphone"
                text={selectedAdherent.telephone}
                icon="ic:baseline-phone"
                modified_text={
                  newValueAdherent?.telephone !== selectedAdherent.telephone
                    ? newValueAdherent?.telephone
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={6}>
              <InfosDisplayer
                label="Entreprise"
                text={selectedAdherent.entreprise}
                icon="mdi:company"
                modified_text={
                  newValueAdherent?.entreprise !== selectedAdherent.entreprise
                    ? newValueAdherent?.entreprise
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={6}>
              <InfosDisplayer
                label="Poste"
                text={selectedAdherent.poste}
                icon="zondicons:travel-case"
                modified_text={
                  newValueAdherent?.poste !== selectedAdherent.poste
                    ? newValueAdherent?.poste
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <InfosDisplayer
                label="Eglise"
                text={selectedAdherent.church.nom}
                icon="majesticons:church"
                modified_text={
                  newValueAdherent?.church?.nom !== selectedAdherent.church?.nom
                    ? newValueAdherent?.church?.nom
                    : ""
                }
              />
            </Grid>
          </Grid>
          {selectedAdherent.isModif ? (
            <div className="btn-part">
              <LoadingButton
                loading={loadingAccept}
                loadingPosition="center"
                color="primary"
                className="btn-option"
                type="submit"
                startIcon={
                  <InlineIcon icon="material-symbols:check-small-rounded" />
                }
                variant="contained"
                onClick={acceptAdherentModif}
              >
                Accepter modification
              </LoadingButton>

              <LoadingButton
                loading={loadingRefuse}
                loadingPosition="center"
                color="error"
                className="btn-option"
                startIcon={<InlineIcon icon="iconoir:cancel" />}
                onClick={refuseAdherentModif}
                variant="contained"
              >
                Refuser modification
              </LoadingButton>
            </div>
          ) : (
            <div className="btn-part">
              <LoadingButton
                loading={loadingAccept}
                loadingPosition="center"
                color="primary"
                className="btn-option"
                type="submit"
                startIcon={
                  <InlineIcon icon="material-symbols:check-small-rounded" />
                }
                variant="contained"
                onClick={acceptAdherent}
              >
                Accepter
              </LoadingButton>

              <LoadingButton
                loading={loadingRefuse}
                loadingPosition="center"
                color="error"
                className="btn-option"
                startIcon={<InlineIcon icon="iconoir:cancel" />}
                onClick={refuseAdherent}
                variant="contained"
              >
                Refuser
              </LoadingButton>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdherentForm;
