import { routePaths } from '@/config';
import { TextField, Button, Box, Grid, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import './SignupForm.styles.scss';

const SignupForm: React.FC = () => {

  const navigate = useNavigate();
  const [sexe, setSexe] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setSexe(event.target.value as string);
  };

  const goTo = (route: string) => {
    navigate(route, { replace: true });
  }

  return (
    <div className="signup-container">
      <div className="header-part">
        <h1 className="title">INSCRIPTION</h1>
        <p className="subtitle"> CRÉER MON COMPTE </p>
      </div>
      <div className="form-part">
        <Box>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} lg={6}>
              <TextField placeholder="Votre prénom"
                variant="outlined"
                label="Prénom"
                type="text" />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField placeholder="Votre nom"
                variant="outlined"
                label="Nom"
                type="text" />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField placeholder="Votre nom d'utilisateur"
                variant="outlined"
                label="Nom d'utilisateur"
                type="text" />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField placeholder="Votre adresse email"
                variant="outlined"
                label="Email"
                type="text" />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField placeholder="Votre numéro de téléphone (Whatsapp)"
                variant="outlined"
                label="Numéro de téléphone (Whatsapp)"
                type="text" />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField placeholder="Votre mot de passe"
                variant="outlined"
                label="Mot de passe"
                type="text" />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="id-sexe">Sexe</InputLabel>
                <Select
                  labelId="id-sexe"
                  value={sexe}
                  label="Sexe"
                  onChange={handleChange}
                >
                  <MenuItem value={'homme'}>Homme</MenuItem>
                  <MenuItem value={'femme'}>Femme</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField placeholder="Votre âge"
                variant="outlined"
                label="Âge"
                type="text" />
            </Grid>
          </Grid>
        </Box>

        <Button variant="contained"
          color='tertiary'
          sx={{ marginTop: '30px' }}
          onClick={() => {goTo(routePaths.authChildren.signInAbsolute) }}
          >S'INSCRIRE
        </Button>

      </div>
      <div className="footer-part">
        <Link className="link-primary"
          to={routePaths.authChildren.signInAbsolute} >
          Retourner sur la page de connexion
        </Link>
      </div>
    </div>
  )
}

export default SignupForm;
