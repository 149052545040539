import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { TThemeColors } from "@/utils/convertThemeColorsToRootColors";

export const SideMenuHeader = styled.button`
  height: 49px;
  background: none;
  border: none;
  color: ${(props) => (props.theme.colors.ui as TThemeColors).secondary};
  font-weight: normal;
  font-size: 14px;
  width: 100%;
  text-align: left;
  cursor: pointer;
`;

const sideMenuLink = css`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.theme.colors.ui as TThemeColors).light};
  height: 49px;
  padding-left: 18px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const SideMenuContainer = styled.div<{
  duration: number;
  open: boolean;
}>`
  position: relative;
  color: #fff;
  padding-left: 18px;
  border-radius: 5px;
  height: 49px;
  overflow: hidden;

  ${({ duration }) =>
    `transition: height ${duration / 1000}s ease, margin-bottom ${
      duration / 1000
    }s ease, margin-top 0.05s ease`};

  ${({ open }) => {
    if (open) {
      return css`
        margin-top: 1rem;
        margin-right: 1rem;
        background-color: ${(props) =>
          (props.theme.colors.ui as TThemeColors).light};

        ${SideMenuHeader.toString()} {
          color: ${(props) =>
            (props.theme.colors.ui as TThemeColors).tertiary} !important;
        }
      `;
    }
  }}

  ${SideMenuHeader.toString()} {
    height: 49px;
    background: none;
    border: none;
    color: ${(props) => (props.theme.colors.ui as TThemeColors).secondary};
    font-weight: 900;
    font-size: 14px;
    width: 100%;
    text-align: left;
    cursor: pointer;

    .start-icon {
      font-size: 18px;
      font-weight: bold;
      margin-right: 15px;
    }

    .end-icon {
      margin-left: 15px;
    }
  }

  ul {
    list-style-type: none;
    margin-bottom: 1rem;

    & > li a {
      display: block;
      color: ${(props) => (props.theme.colors.ui as TThemeColors).tertiary};
      padding: 0.75rem 0;
      padding-left: 15px;
    }

    & > li a.active {
      font-weight: bold;
    }

    & > li:not(:last-child) {
      border-bottom: 1px solid rgba(196, 196, 196, 0.53);
    }
  }
`;

export const SideMenuLinkContainer = styled(Link)<{
  active: string;
  down_lg: string;
}>`
  ${sideMenuLink};

  ${({ active, down_lg }) =>
    down_lg === "false" && active === "true" &&
    css`
      &::before {
        content: "";
        position: absolute;
        z-index:-2;
        top: -5px;
        right: 0;
        width: 5px;
        height: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 7.5px 8.5px 0 7.5px
          ${(props) => (props.theme.colors.ui as TThemeColors).light};
      }

      &::after {
        content: "";
        position: absolute;
        z-index:-2;
        bottom: -5px;
        right: 0;
        width: 5px;
        height: 5px;
        border-top-right-radius: 5px;
        box-shadow: 7.5px -8.5px 0 7.5px ${(props) => (props.theme.colors.ui as TThemeColors).light};
      }

      background: ${(props) =>
        (props.theme.colors.ui as TThemeColors).light};
      color: ${(props) =>
        (props.theme.colors.ui as TThemeColors).primary} !important;
    `};

    ${({ active, down_lg }) =>
    down_lg === "true" && active === "true" &&
    css`
      &::before {
        display:none ;
      }

      &::after {
        display:none ;
      }

      border-radius: 5px;
      background: ${(props) =>
        (props.theme.colors.ui as TThemeColors).light};
      color: ${(props) =>
        (props.theme.colors.ui as TThemeColors).medium} !important;
    `};

    .active-container {
      ${({ active}) => active=== "true" && css`
          position:absolute;
          top: 5px;
          left: 5px;
          width: calc(100% - 5px);
          height: calc(100% - 10px);
          z-index:-1;
          background: #D3E5E5;
          border-radius: 5px;
      `};

      ${({ active, down_lg}) => active=== "true" && down_lg=== "true" && css`
          position:absolute;
          top: 5px;
          left: 5px;
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          z-index:-1;
          background: #D3E5E5;
          border-radius: 5px;
      `};      
 
    }

    svg {
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
    }
`;
