import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";

// ADHERENTS

export const apiListAdherent = (
  page: number,
  limit: number,
  search: string = "",
  legend: string = "",
  modifProgress: string = "false"
): Promise<AxiosResponse<any, any>> => {
  let queryStringToSend = "";

  if (search !== "")
    queryStringToSend = queryStringToSend.concat("&search=", search);

  if (legend !== "")
    queryStringToSend = queryStringToSend.concat("&status=", legend);
  
  if (modifProgress !== "false")
    queryStringToSend = queryStringToSend.concat("&isModif=", modifProgress);

  return axios.get(
    "/ListUsers?page=" + page + "&nbre=" + limit + "" + queryStringToSend
  );
};

export const apiAcceptAdherent = (
  member_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/ActivateUser/" + member_id);
};

export const apiRefuseAdherent = (
  member_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/DeactivateUser/" + member_id);
};

export const apiGetAdherentEditedChange = (
  member_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get("/UserDetails/" + member_id);
};

export const apiAdherentProfileValidate = (
  member_id: string,
  isValidated: boolean
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/ProfileValidation/" + member_id, { valid: isValidated });
};

//MEMBRES

export const apiListMembres = (
  page: number,
  limit: number,
  search: string = ""
): Promise<AxiosResponse<any, any>> => {
  let queryStringToSend = "";
  if (search !== "")
    queryStringToSend = queryStringToSend.concat("&search=", search);
  return axios.get(
    "/ListMembers?page=" + page + "&nbre=" + limit + "" + queryStringToSend
  );
};

export const apiCreateMembre = (
  data: any
): Promise<AxiosResponse<any, any>> => {
  return axios.post("/CreateMember", data);
};

export const apiEditMembre = (
  data: any,
  member_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("UpdateMember/" + member_id, data);
};

export const apiResetPasswordMembre = (
  member_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/reset-password/membre/" + member_id);
};

//TABLEAU DE BORD

export const apiStatDashboard = (): Promise<AxiosResponse<any, any>> => {
  return axios.get("/StatsDashboard");
};

export const apiEvolutionAdherent = (): Promise<AxiosResponse<any, any>> => {
  return axios.get("/evolution/dashboard");
};

export const apiRecentAdhesion = (): Promise<AxiosResponse<any, any>> => {
  return axios.get("/recent-adhesion/dashboard");
};
