import React, { createContext, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import "./Utilisateurs.style.scss";
import MiniPiehart from "@/components/Mini-pie-chart/MiniPieChart";
import TableAdherentManager from "@/components/TableAdherentManager/TableAdherentManager";
import { apiListAdherent } from "../../api/profile.api";
import { Toastify } from "@/utils/toast";
import { TAdherent } from "../../types";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  Box,
  Chip,
  Grid,
} from "@mui/material";

type TListLegend = "ACCEPTE" | "REFUSE" | "EN_ATTENTE";

export const UtilisateurContext = createContext<{
  uiUpdateAdherentlist: (data?: any) => void;
}>({
  uiUpdateAdherentlist: (data?: any) => {},
});

const CtUtilisateurs: React.FC = () => {
  const [legend, setLegend] = useState<TListLegend | "">("");
  const [modifProgress, setModifProgress] = useState<"true" | "false">("false");

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const [nbrIsModif, setNbrIsModif] = useState(0);
  const [nbrAccepte, setNbrAccepte] = useState(0);
  const [nbrRefuse, setNbrRefuse] = useState(0);
  const [nbrAttente, setNbrAttente] = useState(0);
  const [nbrTotal, setNbrTotal] = useState(0);

  const [reqPage, setReqPAge] = useState(1);
  const [reqLimit, setLimit] = useState("5");
  const [totalLigne, setTotalLigne] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [adherentsList, setAdherentsList] = useState<TAdherent[]>([]);

  const getAllAdherents = useCallback(() => {
    setLoading(true);
    apiListAdherent(
      reqPage,
      parseInt(reqLimit),
      searchText,
      legend,
      modifProgress
    )
      .then((response: any) => {
        let data = response.data.user;
        let tempTotalLigne = response.data.total;
        let tempTotalPage = response.data.page;

        let tempNbrIsModif = response.data.stats.isModif;
        let tempNbrAccepte = response.data.stats.accepte;
        let tempNbrRefuse = response.data.stats.refuse;
        let tempNbrAttente = response.data.stats.en_attente;
        let tempNbrTotal = response.data.total;

        setAdherentsList(data as TAdherent[]);
        setTotalLigne(tempTotalLigne);
        setTotalPage(tempTotalPage);

        setNbrIsModif(tempNbrIsModif);
        setNbrAccepte(tempNbrAccepte);
        setNbrRefuse(tempNbrRefuse);
        setNbrAttente(tempNbrAttente);
        setNbrTotal(tempNbrTotal);
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reqPage, reqLimit, searchText, legend, modifProgress]);

  useEffect(() => {
    getAllAdherents();
  }, [getAllAdherents]);

  const uiUpdateAdherentlist = (editedRecord: TAdherent) => {
    let tempAdherentsList = [...adherentsList];
    let selectedIndex = tempAdherentsList.findIndex(
      (elt) => elt.id === editedRecord.id
    );
    tempAdherentsList[selectedIndex] = { ...editedRecord };
    setAdherentsList([...tempAdherentsList]);
    Toastify("Modification effectuée", "success", 2000);
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setReqPAge(page);
  };

  return (
    <UtilisateurContext.Provider
      value={{ uiUpdateAdherentlist: uiUpdateAdherentlist }}
    >
      <>
        <div className="p-users-container">
          <div className="kpi-part">
            <div
              className={classNames("kpi-card modif", {
                active: modifProgress === "true",
              })}
              onClick={() => {
                setModifProgress("true");
              }}
            >
              <span className="value">{nbrIsModif}</span>
              <span className="text">Profil(s) modifié(s)</span>
            </div>
            <div
              className={classNames("kpi-card ")}
              onClick={() => {
                setLegend("");
              }}
            >
              <span className="value">{nbrTotal}</span>
              <span className="text">Total des adhésions</span>
              <div className="mini-pie">
                <MiniPiehart
                  value1={nbrAccepte}
                  value2={nbrRefuse}
                  value3={nbrAttente}
                  value4={0}
                  color1={"#006C69"}
                  color2={"#872D2D"}
                  color3={"#F5B544"}
                  color4={"#ff1744"}
                  height={30}
                  width={30}
                  interRadius={5}
                />
              </div>
            </div>
            <div
              className={classNames("kpi-card accepte", {
                active: legend === "ACCEPTE",
              })}
              onClick={() => {
                setLegend("ACCEPTE");
              }}
            >
              <span className="percentage">
                {" "}
                {Math.round((nbrAccepte / nbrTotal) * 100) | 0}%{" "}
              </span>
              <span className="value">{nbrAccepte}</span>
              <span className="text">Adhésion(s) acceptée(s)</span>
            </div>
            <div
              className={classNames("kpi-card refuse", {
                active: legend === "REFUSE",
              })}
              onClick={() => {
                setLegend("REFUSE");
              }}
            >
              <span className="percentage">
                {" "}
                {Math.round((nbrRefuse / nbrTotal) * 100) | 0}%{" "}
              </span>
              <span className="value">{nbrRefuse}</span>
              <span className="text">Adhesion(s) refusée(s)</span>
            </div>
            <div
              className={classNames("kpi-card attente", {
                active: legend === "EN_ATTENTE",
              })}
              onClick={() => {
                setLegend("EN_ATTENTE");
              }}
            >
              <span className="percentage">
                {" "}
                {Math.round((nbrAttente / nbrTotal) * 100) | 0}%{" "}
              </span>
              <span className="value">{nbrAttente}</span>
              <span className="text">Adhésion(s) en attente</span>
            </div>
          </div>
          {(legend !== "" || modifProgress !== "false") && (
            <Box>
              <Grid container>
                <Grid item xs={12} sm={12} lg={12}>
                  {legend !== "" && (
                    <Chip
                      sx={{ marginLeft: "20px" }}
                      label={legend}
                      onDelete={() => {
                        setLegend("");
                      }}
                    />
                  )}
                  {modifProgress !== "false" && (
                    <Chip
                      sx={{ marginLeft: "20px" }}
                      label={"PROFIL(S) MODIFIÉ(S)"}
                      onDelete={() => {
                        setModifProgress("false");
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
          <div className="table-part">
            <TableAdherentManager
              main_title="Liste des adhérents"
              setSearchText={setSearchText}
              data={adherentsList}
              loading={loading}
            />
            <div className="table-part__table-footer">
              <span className="table-part__table-footer__footer-indicator">
                Affichage de <b>{parseInt(reqLimit) * (reqPage - 1) + 1}</b> à{" "}
                <b>
                  {parseInt(reqLimit) * (reqPage - 1) + adherentsList.length}
                </b>{" "}
                sur <b>{totalLigne}</b> résultats
              </span>

              <FormControl
                sx={{ m: 1, minWidth: 100, marginLeft: "10px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">Lignes</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={reqLimit}
                  label="Lignes"
                  onChange={(e) => {
                    setLimit(e.target.value);
                  }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>

              <Pagination
                className="table-part__table-footer__pagination"
                count={totalPage}
                onChange={handlePaginationChange}
                shape="rounded"
              />
            </div>
          </div>
        </div>
      </>
    </UtilisateurContext.Provider>
  );
};

export default CtUtilisateurs;
