import React, { useEffect, useState } from "react";
import { routePaths } from "@/config";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./ResetPasswordForm.styles.scss";
import { Toastify } from "@/utils/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiResetPasswordMember } from "../../api/auth.api";
import { TResetPassword } from "../../types";
import { selectEmail } from "@/store/reducers/temp_data/temp_data.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

type PropsFromRedux = ConnectedProps<typeof connector>;

const ResetPasswordFormFct: React.FC<PropsFromRedux> = ({
  email_from_redux,
}) => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const validationSchema = yup.object({
    otp: yup.string().required(" le code otp est obligatoire "),
    new_password: yup
      .string()
      .required("le mot de passe est obligatoire")
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Au minimum ( 8 caractères, 01 majuscule, 01 minuscule, 01 chiffre et 01 caractère spéciale)"
      ),
    // .min(5, 'Votre mot de passe doit contenir au moins 5 caratères'),
    confirmed_password: yup
      .string()
      .required("le mot de passe est obligatoire")
      .oneOf(
        [yup.ref("new_password")],
        "Les mots de passes doivent être identiques"
      ),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
      new_password: "",
      confirmed_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const dataToSend: TResetPassword = {
        email: email_from_redux,
        code_otp: values.otp,
        password: values.confirmed_password,
      };
      resetPasswordSubmit(dataToSend);
    },
  });

  const resetPasswordSubmit = (data: TResetPassword) => {
    setLoading(true);

    apiResetPasswordMember(data)
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        navigate(routePaths.authChildren.signInAbsolute, { replace: false });
        Toastify(
          `Votre mot de passe été réinitialiser avec succès .`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoading(false);
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      });
  };

  useEffect(() => {
    email_from_redux === "" &&
      Toastify(
        `Veuillez saisir à nouveau votre addresse mail svp`,
        "warning",
        4000
      );
    email_from_redux === "" &&
      navigate(routePaths.authChildren.forgottenPasswordAbsolute, {
        replace: false,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="reset-password-container">
      <div className="header-part">
        <h1 className="title">RÉINITIALISATION DE MOT DE PASSE</h1>
        <p className="subtitle"> REMPLIR LE FORMULAIRE CI-DESSOUS </p>
      </div>
      <form className="form-part" onSubmit={formik.handleSubmit}>
        <TextField
          placeholder="Code OTP"
          variant="outlined"
          label="OTP"
          name="otp"
          onChange={formik.handleChange}
          value={formik.values.otp}
          error={formik.touched.otp && Boolean(formik.errors.otp)}
          helperText={formik.touched.otp && formik.errors.otp}
          type="text"
        />
        <TextField
          placeholder="Mot de passe"
          variant="outlined"
          label="Mot de passe"
          type={showPassword1 ? "text" : "password"}
          name="new_password"
          onChange={formik.handleChange}
          value={formik.values.new_password}
          error={
            formik.touched.new_password && Boolean(formik.errors.new_password)
          }
          helperText={formik.touched.new_password && formik.errors.new_password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword1}
                  onMouseDown={handleMouseDownPassword1}
                >
                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ marginTop: "15px" }}
        />
        <TextField
          placeholder="Mot de passe"
          variant="outlined"
          label="Confirmation de mot de passe"
          type={showPassword2 ? "text" : "password"}
          name="confirmed_password"
          onChange={formik.handleChange}
          value={formik.values.confirmed_password}
          error={
            formik.touched.confirmed_password &&
            Boolean(formik.errors.confirmed_password)
          }
          helperText={
            formik.touched.confirmed_password &&
            formik.errors.confirmed_password
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword2}
                  onMouseDown={handleMouseDownPassword2}
                >
                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ marginTop: "15px" }}
        />
        <LoadingButton
          loading={loading}
          loadingPosition="center"
          variant="contained"
          color="tertiary"
          sx={{ marginTop: "30px" }}
          type="submit"
        >
          RÉINITIALISER LE MOT DE PASSE
        </LoadingButton>
      </form>
      <div className="footer-part">
        <Link
          className="link-primary"
          to={routePaths.authChildren.signInAbsolute}
        >
          {" "}
          Connexion{" "}
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  email_from_redux: selectEmail,
});

const connector = connect(mapStateToProps);
const ResetPasswordForm = connector(ResetPasswordFormFct);

export default ResetPasswordForm;
