import React from "react";
import { routePaths } from "@/config";
import { Toastify } from "@/utils/toast";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./SigninForm.styles.scss";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import { apiLoginMember } from "../../api/auth.api";
import { TSignIn } from "../../types";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { TUserConnected } from "@/features/profile/types";
import { store } from "@/store";
import {
  getUserConnected,
  getToken,
} from "@/store/reducers/member/member.actions";
import { selectEmail } from "@/store/reducers/temp_data/temp_data.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";

type PropsFromRedux = ConnectedProps<typeof connector>;
const SigninFormFct: React.FC<PropsFromRedux> = ({ email_from_redux }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const validationSchema = yup.object({
    identifier: yup.string().required(" l'email est obligatoire "),
    password: yup.string().required("le mot de passe est obligatoire"),
    // .min(5, 'Votre mot de passe doit contenir au moins 5 caratères')
  });

  const formik = useFormik({
    initialValues: {
      identifier: email_from_redux || "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let dataTosend: TSignIn = {
        email: values.identifier,
        mot_passe: values.password,
      };
      loginSubmit(dataTosend);
    },
  });

  const loginSubmit = (data: TSignIn) => {
    setLoading(true);
    apiLoginMember(data)
      .then((response: any) => {
        let tempUserConnected = response.data;
        let token = response.data.token;

        delete tempUserConnected.token;
        delete tempUserConnected.status;

        store.dispatch(getUserConnected(tempUserConnected as TUserConnected));
        store.dispatch(getToken(token));
        // Code goes here
        navigate(routePaths.profileChildren.dashboardAbsolute, {
          replace: false,
        });
        Toastify(
          `Bienvenue ${
            (tempUserConnected as TUserConnected).prenoms
          }, tu es maintenant connecté(e).`,
          "success",
          5000
        );
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="signin-container">
      <div className="left-part">
        <div className="header-part">
          <p className="intro">
            Bienvenue sur la plateforme <br />
            Admin du RESHO
          </p>

          <h1 className="title underlined">Connectez-vous</h1>
        </div>
        <form className="form-part" onSubmit={formik.handleSubmit}>
          <TextField
            placeholder="Votre adresse email ou votre identifiant"
            variant="outlined"
            label="Email ou Identifiant"
            name="identifier"
            onChange={formik.handleChange}
            value={formik.values.identifier}
            error={
              formik.touched.identifier && Boolean(formik.errors.identifier)
            }
            helperText={formik.touched.identifier && formik.errors.identifier}
            type="text"
          />
          <TextField
            placeholder="Votre mot de passe"
            variant="outlined"
            label="Mot de passe"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ marginTop: "15px" }}
          />
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            variant="contained"
            color="tertiary"
            sx={{ marginTop: "30px" }}
            type="submit"
          >
            SE CONNECTER
          </LoadingButton>
        </form>
        <div className="footer-part">
          <p>
            {" "}
            J'ai oublié mon
            <Link
              className="link-primary"
              to={routePaths.authChildren.forgottenPasswordAbsolute}
            >
              {" "}
              Mot de passe{" "}
            </Link>
          </p>
        </div>
      </div>
      <div className="right-part">
        <p className="title underlined">
          Nous sommes heureux <br />
          de vous revoir!
        </p>
        <img
          src={require("@/assets/images/png/bg-auth.png")}
          alt="bg-auth"
          className="bg-auth"
        />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  email_from_redux: selectEmail,
});

const connector = connect(mapStateToProps);
const SigninForm = connector(SigninFormFct);

export default SigninForm;
