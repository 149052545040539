import { axios } from "@/lib/axios"
import { AxiosResponse } from "axios";
import { TForgotPassword, TResetPassword, TSignIn } from "../types";


export const  apiLoginMember = ( data:TSignIn ):Promise<AxiosResponse<any, any>> => {
    return axios.post('/LoginMember',data);
}

export const  apiForgotPasswordMember = ( data:TForgotPassword ):Promise<AxiosResponse<any, any>> => {
    return axios.post('/SendMemberMail',data);
}

export const  apiResetPasswordMember = ( data:TResetPassword ):Promise<AxiosResponse<any, any>> => {
    return axios.patch('/ChangeMemberPassword',data);
}

