import React, { useState } from "react";
import "./Header.styles.scss";
import {
  MenuOpenRounded,
} from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { toggleSidebar } from "@/store/reducers/sidebar/sidebar.actions";
import { persistor, store, useAppDispatch } from "@/store";
import {  useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import { InlineIcon } from "@iconify/react";
import { selectUserConnected } from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TUserConnected } from "../../types";
import { getToken } from "@/store/reducers/member/member.actions";
import { axios } from "@/lib/axios";

type TProps = {
  title: string,
  subtitle?: string
}
type PropsFromRedux = ConnectedProps<typeof connector>;

const HeaderFunction: React.FC<TProps & PropsFromRedux> = ({
  title,
  subtitle='',
  userConnected
}) => {


  const theme = useTheme();
  const matches_up_sm = useMediaQuery(theme.breakpoints.up('lg'));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openLogout, setOpenLogout] = useState(false);
  const handleClickOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const logout = () => {
    store.dispatch({ type: 'CLEAR_STORE' });
    localStorage.clear();
    navigate(routePaths.auth, { replace: true });
    store.dispatch(getToken(''));
    persistor.purge();
    delete axios.defaults.headers.common["Authorization"];
  }




  return (
    <>
      <div className="c-header">
        <div className="mobile-header">
          <img
            src={require("@/assets/images/png/logo-mini.png")}
            alt="logo-mini"
            className="logo-mini"
          />
          <div className="title-part">
            <h2 className="title">{title}</h2>
            <p className="subtitle">{subtitle}</p>
          </div>
          <div className="rounded-square-btn-head" onClick={() => { dispatch(toggleSidebar()) }} >
              <MenuOpenRounded sx={{color:'var(--ui-light)'}} />
          </div>
        </div>

        <img src={require("@/assets/images/png/logo-vase.png")}
          alt="logo-mini"
          className="logo-mini-md"
        />
        <div className="title-part">
          <h2 className="title">{title}</h2>
        </div>

        <div className="rounded-square-btn" onClick={() => { dispatch(toggleSidebar()) }} >
          <MenuOpenRounded sx={{color:'var(--ui-light)'}} />
        </div>

        {
          matches_up_sm &&
            <div className="infos-user" onClick={handleClickMenu} >

            <div className="text">
              <span className="fullname"> { (userConnected as TUserConnected).prenoms + " " + (userConnected as TUserConnected).nom  } </span>
              <span className="role"> { (userConnected as TUserConnected).role.libelle.toUpperCase() } </span>
            </div>
            <img
              src={require("@/assets/images/png/avatar.png")}
              alt="sidebar-logo"
              className="icon-user"
            />
            <div className="icon-end">
              <InlineIcon icon="akar-icons:chevron-down" />
            </div>
          </div>
        }

      </div>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <MenuItem sx={{display:'none'}} onClick={() => { }}>
          <ListItemIcon>
            <InlineIcon icon="bxs:user-detail" className="from-menu" />
          </ListItemIcon>
          Mon profil
        </MenuItem>

        <MenuItem onClick={handleClickOpenLogout}>
          <ListItemIcon>
            <InlineIcon icon="charm:power" className="from-menu" />
          </ListItemIcon>
          Se déconnecter
        </MenuItem>

      </Menu>

      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Déconnexion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de vous déconnecter. Êtes-vous sûr(e) de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="tertiary" onClick={handleCloseLogout}>Annuler</Button>
          <Button color="tertiary" onClick={logout} autoFocus>
            Déconnecter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
});


const connector = connect(mapStateToProps);
const Header = connector(HeaderFunction);
export default Header;
