import { InlineIcon } from "@iconify/react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import "./MemberForm.style.scss";
import { apiCreateMembre, apiEditMembre } from "../../api/profile.api";
import { Toastify } from "@/utils/toast";
import { LoadingButton } from "@mui/lab";
import { TUserConnected } from "../../types";
import { MemberContext } from "../../pages-content/Membres-content/Membres.content";

type TMemberForm = {
  selectedMember?: TUserConnected;
  closeClick: () => void;
};

const MemberForm: React.FC<TMemberForm> = ({ selectedMember, closeClick }) => {
  const [loading, setLoading] = useState(false);

  const { uiNewMemberList, uiUpdateMemberList } = useContext(MemberContext);

  const roleList: { id: string; value: string }[] = [
    { id: "6413ad81c7bde7f5a3ba9bdd", value: "ADMINISTRATEUR" },
    { id: "6413ad81c7bde7f5a3ba9bdc", value: "MEMBRE" },
  ];

  const [role, setRole] = useState<{ id: string; value: string }>({
    id: selectedMember?.role.id || " ",
    value: selectedMember?.role.libelle || " ",
  });
  const handleRoleChange = (
    event: any,
    value: { id: string; value: string } | null
  ) => {
    console.log(value);
    if (value == null) {
      setRole({ id: "", value: "" });
    } else {
      setRole(value as { id: string; value: string });
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required(" Le nom du membre est obligatoire "),
    firstname: yup.string().required(" Le prénom du membre est obligatoire "),
    email: yup
      .string()
      .email("L'e-mail n'est valide")
      .required(" L'e-mail du membre est obligatoire "),
    telephone: yup
      .string()
      .required(" Le téléphone du membre est obligatoire "),
    role: yup.string().required(" Le rôle du membre est obligatoire "),
  });

  const formik = useFormik({
    initialValues: {
      name: selectedMember?.nom || " ",
      firstname: selectedMember?.prenoms || " ",
      email: selectedMember?.email || " ",
      telephone: selectedMember?.telephone || " ",
      role: selectedMember?.role.id || " ",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      selectedMember ? editMembre() : createMembre();
    },
  });

  const editMembre = () => {
    let dataToSend = {
      nom: formik.values.name,
      prenoms: formik.values.firstname,
      email: formik.values.email,
      telephone: formik.values.telephone,
      role: role.id,
    };

    setLoading(true);
    apiEditMembre(dataToSend, (selectedMember as TUserConnected).id)
      .then((response: any) => {
        // Code goes here
        let dataToSend = response.data as TUserConnected;
        uiUpdateMemberList(dataToSend);
        closeClick();
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createMembre = () => {
    let dataToSend = {
      nom: formik.values.name,
      prenoms: formik.values.firstname,
      email: formik.values.email,
      telephone: formik.values.telephone,
      role: role.id,
    };

    setLoading(true);
    apiCreateMembre(dataToSend)
      .then((response: any) => {
        // Code goes here
        let dataToSend = response.data as TUserConnected;
        uiNewMemberList(dataToSend);
        closeClick();
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form className="member-form" onSubmit={formik.handleSubmit}>
      {selectedMember ? (
        <span className="title"> Edition d'un membre </span>
      ) : (
        <span className="title"> Création d'un membre </span>
      )}

      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            placeholder="Nom(s)"
            variant="outlined"
            name="name"
            label="Nom(s)"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InlineIcon icon="mdi:user" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            placeholder="Prénom(s)"
            variant="outlined"
            name="firstname"
            label="Prénom(s)"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.firstname}
            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
            helperText={formik.touched.firstname && formik.errors.firstname}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InlineIcon icon="mdi:user" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            placeholder="E-mail"
            variant="outlined"
            name="email"
            label="E-mail"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InlineIcon icon="material-symbols:mail" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <TextField
            placeholder="Téléphone"
            variant="outlined"
            name="telephone"
            label="Téléphone"
            className="my-text-field"
            onChange={formik.handleChange}
            value={formik.values.telephone}
            error={formik.touched.telephone && Boolean(formik.errors.telephone)}
            helperText={formik.touched.telephone && formik.errors.telephone}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InlineIcon icon="ic:baseline-phone-enabled" rotate={1} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Autocomplete
            id="id-service-select"
            options={roleList}
            value={role}
            onChange={handleRoleChange}
            autoHighlight
            getOptionLabel={(option) => option.value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className="my-text-field"
                placeholder="Rôle"
                label="Rôle"
                variant="outlined"
                name="role"
                onChange={formik.handleChange}
                value={formik.values.role}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role && formik.errors.role}
              />
            )}
          />
        </Grid>
      </Grid>

      <div className="btn-part">
        <LoadingButton
          loading={loading}
          loadingPosition="center"
          color="secondary"
          className="btn-option"
          type="submit"
          startIcon={<InlineIcon icon="iconoir:save-floppy-disk" />}
          variant="contained"
        >
          Enregistrer
        </LoadingButton>

        <Button
          color="inherit"
          className="btn-option"
          startIcon={
            <InlineIcon icon="material-symbols:cancel-outline-rounded" />
          }
          onClick={() => {}}
          variant="contained"
        >
          Annuler
        </Button>
      </div>
    </form>
  );
};

export default MemberForm;
