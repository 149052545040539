import React, { useState } from "react";
import "./TableMembreManager.styles.scss";
import { InlineIcon } from "@iconify/react";
import {
  TextField,
  InputAdornment,
  Button,
  Skeleton,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { apiResetPasswordMembre } from "@/features/profile/api/profile.api";
import { Toastify } from "@/utils/toast";
import SideModal from "../SideModal/SideModal";
import { LoadingButton } from "@mui/lab";
import MemberForm from "@/features/profile/forms/MemberForm/MemberForm";
import { selectUserConnected } from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TUserConnected } from "@/features/profile/types";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

type TTableMembreManagerProps = {
  main_title: string;
  setSearchText: (value: React.SetStateAction<string>) => void;
  data: TUserConnected[];
  loading: boolean;
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const TableMembreManagerFct: React.FC<
  TTableMembreManagerProps & PropsFromRedux
> = ({ main_title = "", setSearchText, data = [], loading, userConnected }) => {
  const [inputValue, setInputValue] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openReset, setOpenReset] = useState(false);
  const handleClickOpenReset = () => setOpenReset(true);
  const handleCloseReset = () => setOpenReset(false);
  const [loadingReset, setLoadingReset] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const resetMemberPasssword = () => {
    setLoadingReset(true);
    apiResetPasswordMembre(data[selectedIndex].id)
      .then((response: any) => {
        // Code goes here
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoadingReset(false);
      });
  };

  return (
    <>
      <div className="c-table-membres-container">
        <div className="c-table-membres-container__table-option">
          <span className="table-title">{main_title}</span>
          <TextField
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setTimeout(() => {
                setSearchText(e.target.value);
              }, 1000);
            }}
            className="search-input"
            placeholder="Recherche ... "
            variant="outlined"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InlineIcon icon="bi:search" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            style={{ display: "none" }}
            className="option-btn"
            color="primary"
            startIcon={
              <InlineIcon icon="material-symbols:export-notes-outline-rounded" />
            }
          >
            Exporter
          </Button>
        </div>
        <PerfectScrollbar className="c-table-membres-container__scroll-container">
          <div className="c-table-membres-container__scroll-container__table-header">
            <div className="header-item" style={{ width: "250px" }}>
              {" "}
              Noms & Prénom(s){" "}
            </div>
            <div className="header-item" style={{ width: "300px" }}>
              {" "}
              Email{" "}
            </div>
            <div className="header-item" style={{ width: "150px" }}>
              {" "}
              Téléphone{" "}
            </div>
            <div className="header-item" style={{ width: "150px" }}>
              {" "}
              Rôle{" "}
            </div>
            <div className="header-item" style={{ width: "800px" }}>
              {" "}
            </div>
          </div>
          <div className="c-table-membres-container__scroll-container__table-body">
            {loading ? (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    padding: "20px",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={"100%"}
                    height={30}
                  />
                  <Skeleton
                    variant="text"
                    animation="pulse"
                    width={"100%"}
                    height={30}
                  />
                </div>
              </>
            ) : (
              <>
                {data.map((elt: TUserConnected, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="c-table-membres-container__scroll-container__table-body__table-row">
                        <div className="row-item" style={{ width: "250px" }}>
                          <ListItem sx={{ padding: 0 }}>
                            <ListItemAvatar>
                              <Avatar>
                                <InlineIcon icon="icon-park-solid:avatar" />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={elt.nom}
                              secondary={elt.prenoms}
                            />
                          </ListItem>
                        </div>
                        <div className="row-item" style={{ width: "300px" }}>
                          {" "}
                          {elt.email}{" "}
                        </div>
                        <div className="row-item" style={{ width: "150px" }}>
                          {" "}
                          {elt.telephone}{" "}
                        </div>
                        <div className="row-item" style={{ width: "150px" }}>
                          {" "}
                          {elt.role.libelle}{" "}
                        </div>
                        <div className="row-item" style={{ width: "800px" }}>
                          {(userConnected as TUserConnected).role.libelle ===
                            "Administrateur" && (
                            <>
                              <CustomTooltip title="Modifier membre">
                                <IconButton
                                  className="icon-btn"
                                  color="primary"
                                  onClick={() => {
                                    handleOpen();
                                    setSelectedIndex(index);
                                  }}
                                >
                                  <InlineIcon icon="material-symbols:edit-note-rounded" />
                                </IconButton>
                              </CustomTooltip>
                              {false && (
                                <CustomTooltip title="réinitialiser mot de passe">
                                  <IconButton
                                    className="icon-btn"
                                    color="info"
                                    onClick={() => {
                                      handleClickOpenReset();
                                      setSelectedIndex(index);
                                    }}
                                  >
                                    <InlineIcon icon="mdi:password-reset" />
                                  </IconButton>
                                </CustomTooltip>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
                {data.length === 0 && (
                  <div className="c-table-adherents-container__scroll-container__table-body__table-row">
                    <div className="row-item">Aucun résultat</div>
                  </div>
                )}
              </>
            )}
          </div>
        </PerfectScrollbar>
      </div>
      <SideModal open={open} onClose={handleClose} closeClick={handleClose}>
        <>
          <MemberForm
            selectedMember={data[selectedIndex]}
            closeClick={handleClose}
          />
        </>
      </SideModal>
      <Dialog
        open={openReset}
        onClose={handleCloseReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Réinitialisation de mot de passe"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de réinitialiser le mot de passe de
            <br />
            <b>
              {data[selectedIndex]?.nom + " " + data[selectedIndex]?.prenoms}{" "}
            </b>{" "}
            .
            <br />
            <br /> <b>Êtes-vous sûr(e) de vouloir le faire ?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCloseReset}>
            Annuler
          </Button>
          <LoadingButton
            loading={loadingReset}
            loadingPosition="center"
            color="info"
            onClick={() => {
              handleCloseReset();
              resetMemberPasssword();
            }}
            autoFocus
          >
            Réinitialiser
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps);
const TableMembreManager = connector(TableMembreManagerFct);

export default TableMembreManager;
