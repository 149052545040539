import { TMenu } from "@/features/profile/types";

import { routePaths } from "./routePaths";


export const menus1: TMenu[] = [
  {
    icon: "material-symbols:donut-small",
    label: "Tableau de bord",
    route: routePaths.profileChildren.dashboardAbsolute,
    exact: true,
  },
  {
    icon: "fluent:people-team-20-filled",
    label: "Membres",
    route: routePaths.profileChildren.membersAbsolute,
    exact: true,
  },
  {
    icon: "mdi:users",
    label: "Adhérents",
    route: routePaths.profileChildren.usersAbsolute,
    exact: true,
  },
];


