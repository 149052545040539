import React from 'react';
import './DashboardChartCard.styles.scss';
import MainColumnChart from '../Main-column-chart/MainColumnChart';
import PerfectScrollbar from 'react-perfect-scrollbar';

type TDashboardChartCard = {
    loading: boolean,
    dataForChart: {data_date:string,adhesions:number}[],
}

const DashboardChartCard: React.FC<TDashboardChartCard> = ({
    loading = true,
    dataForChart = [],
}) => {


    return (
        <PerfectScrollbar className="dashboard-chart-card">
            <span className="title"> Évolution des adhésions </span>
            
            <div className="chart-part">
                {
                    loading ?
                        <div className="loading-chart">
                            <img src={require('@/assets/images/gif/chart-load.gif')} alt="load" />
                        </div>
                        :
                        <MainColumnChart dataCharts={dataForChart} />
                }
            </div>
        </PerfectScrollbar>
    )
}

export default DashboardChartCard