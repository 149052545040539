import { TThemeColors } from "../utils/convertThemeColorsToRootColors";

export const colors: TThemeColors = {
    ui: {
        primary: "#006C69",
        secondary: "#CEAD24",
        tertiary: "#009076",
        'tertiary-shade': "#156859",
        dark: "#252525",
        gray: "#8790a1",
        medium: "#363853",
        light: "#F9F9F9",
        'light-shade': "#EDEDED",
        black: "#000000",
        white: "#FFFFFF",
        success: "#75D873",
        error: "#872D2D",
        warning: "#F5B544",
    }

};
