import React from 'react';
import Header from '../containers/Header/Header.container';
import CtDashboard from '../pages-content/Dashboard-content/Dashboard.content';

const Dashboard:React.FC = () => {
  return (
    <>
      <Header title="Tableau de bord"/>
      <CtDashboard />
    </>
  )
}

export default Dashboard;