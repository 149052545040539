import React, { useContext, useState } from "react";
import "./TableAdherentManager.styles.scss";
import { InlineIcon } from "@iconify/react";
import {
  TextField,
  InputAdornment,
  Button,
  Skeleton,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Badge,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import SideModal from "../SideModal/SideModal";
import AdherentForm from "@/features/profile/forms/AdherentForm/AdherentForm";
import { LoadingButton } from "@mui/lab";
import {
  apiAcceptAdherent,
  apiRefuseAdherent,
} from "@/features/profile/api/profile.api";
import { Toastify } from "@/utils/toast";
import { UtilisateurContext } from "@/features/profile/pages-content/Utilisateurs-content/Utilisateurs.content";
import { TAdherent } from "@/features/profile/types";
import * as moment from "moment";
import "moment/locale/fr";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

type TTableAdherentManagerProps = {
  main_title: string;
  setSearchText: (value: React.SetStateAction<string>) => void;
  data?: TAdherent[];
  loading: boolean;
};

const TableAdherentManager: React.FC<TTableAdherentManagerProps> = ({
  main_title = "",
  setSearchText,
  data = [],
  loading,
}) => {
  const { uiUpdateAdherentlist } = useContext(UtilisateurContext);

  const [inputValue, setInputValue] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAccept, setOpenAccept] = useState(false);
  const handleClickOpenAccept = () => setOpenAccept(true);
  const handleCloseAccept = () => setOpenAccept(false);

  const [openRefuse, setOpenRefuse] = useState(false);
  const handleClickOpenRefuse = () => setOpenRefuse(true);
  const handleCloseRefuse = () => setOpenRefuse(false);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingRefuse, setLoadingRefuse] = useState(false);

  const acceptAdherent = () => {
    setLoadingAccept(true);
    apiAcceptAdherent(data[selectedIndex].id)
      .then((response: any) => {
        // Code goes here
        let dataToSend = response.data as TAdherent;
        uiUpdateAdherentlist(dataToSend);
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoadingAccept(false);
      });
  };

  const refuseAdherent = () => {
    setLoadingRefuse(true);
    apiRefuseAdherent(data[selectedIndex].id)
      .then((response: any) => {
        // Code goes here
        let dataToSend = response.data as TAdherent;
        uiUpdateAdherentlist(dataToSend);
      })
      .catch((error) => {
        Toastify(
          `${error?.response?.data?.message || "Une erreur est survenue"}`,
          "error",
          5000
        );
      })
      .finally(() => {
        setLoadingRefuse(false);
      });
  };

  return (
    <>
      <div className="c-table-adherents-container">
        <div className="c-table-adherents-container__table-option">
          <span className="table-title">{main_title}</span>
          <TextField
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setTimeout(() => {
                setSearchText(e.target.value);
              }, 1000);
            }}
            className="search-input"
            placeholder="Recherche ... "
            variant="outlined"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InlineIcon icon="bi:search" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            style={{ display: "none" }}
            className="option-btn"
            color="primary"
            startIcon={
              <InlineIcon icon="material-symbols:export-notes-outline-rounded" />
            }
          >
            Exporter
          </Button>
        </div>
        <PerfectScrollbar className="c-table-adherents-container__scroll-container">
          <div className="c-table-adherents-container__scroll-container__table-header">
            <div className="header-item" style={{ width: "100px" }}>
              {" "}
              Date{" "}
            </div>
            <div className="header-item" style={{ width: "250px" }}>
              {" "}
              Noms & Prénom(s){" "}
            </div>

            <div className="header-item"> Poste </div>
            <div className="header-item"> Entreprise </div>

            <div className="header-item" style={{ width: "300px" }}>
              {" "}
              Email{" "}
            </div>
            <div className="header-item" style={{ width: "150px" }}>
              {" "}
              Téléphone{" "}
            </div>

            <div className="header-item" style={{ width: "150px" }}>
              {" "}
              Status{" "}
            </div>

            <div className="header-item" style={{ width: "400px" }}>
              {" "}
            </div>
          </div>
          <div className="c-table-adherents-container__scroll-container__table-body">
            {loading ? (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    padding: "20px",
                  }}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={"100%"}
                    height={30}
                  />
                  <Skeleton
                    variant="text"
                    animation="pulse"
                    width={"100%"}
                    height={30}
                  />
                </div>
              </>
            ) : (
              <>
                {data.map((elt, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="c-table-adherents-container__scroll-container__table-body__table-row">
                        <div
                          className="row-item"
                          style={{ width: "100px", fontWeight: "bold" }}
                        >
                          {" "}
                          {moment
                            .default(new Date(elt.date_created))
                            .format(" L ")}{" "}
                        </div>
                        <div className="row-item" style={{ width: "250px" }}>
                          <ListItem sx={{ padding: 0 }}>
                            <ListItemAvatar>
                              <Badge
                                color="secondary"
                                variant="dot"
                                invisible={!elt.isModif}
                              >
                                <Avatar>
                                  <InlineIcon icon="icon-park-solid:avatar" />
                                </Avatar>
                              </Badge>
                            </ListItemAvatar>
                            <ListItemText
                              primary={elt.nom}
                              secondary={elt.prenoms}
                            />
                          </ListItem>
                        </div>

                        <div className="row-item"> {elt.poste} </div>
                        <div className="row-item"> {elt.entreprise} </div>

                        <div className="row-item" style={{ width: "300px" }}>
                          {" "}
                          {elt.email}{" "}
                        </div>
                        <div className="row-item" style={{ width: "150px" }}>
                          {" "}
                          {elt.telephone}{" "}
                        </div>

                        <div className="row-item" style={{ width: "150px" }}>
                          {elt.status.libelle === "ACCEPTE" && (
                            <Chip
                              icon={
                                <InlineIcon icon="material-symbols:check-small-rounded" />
                              }
                              label="accepté"
                              color="primary"
                            />
                          )}
                          {elt.status.libelle === "EN_ATTENTE" && (
                            <Chip
                              icon={<InlineIcon icon="mdi:dots-horizontal" />}
                              label="en attente"
                              color="warning"
                            />
                          )}
                          {elt.status.libelle === "REFUSE" && (
                            <Chip
                              icon={<InlineIcon icon="iconoir:cancel" />}
                              label="refusé"
                              color="error"
                            />
                          )}
                        </div>
                        <div className="row-item" style={{ width: "400px" }}>
                          {elt.status.libelle === "EN_ATTENTE" && (
                            <>
                              <CustomTooltip title="Accepter">
                                <IconButton
                                  className="icon-btn"
                                  color="primary"
                                  onClick={() => {
                                    handleClickOpenAccept();
                                    setSelectedIndex(index);
                                  }}
                                >
                                  <InlineIcon icon="material-symbols:check-small-rounded" />
                                </IconButton>
                              </CustomTooltip>
                              <CustomTooltip title="Refuser">
                                <IconButton
                                  className="icon-btn"
                                  color="error"
                                  onClick={() => {
                                    handleClickOpenRefuse();
                                    setSelectedIndex(index);
                                  }}
                                >
                                  <InlineIcon icon="iconoir:cancel" />
                                </IconButton>
                              </CustomTooltip>
                            </>
                          )}
                          <CustomTooltip title="Voir détail">
                            <IconButton
                              className="icon-btn"
                              color="inherit"
                              onClick={() => {
                                handleOpen();
                                setSelectedIndex(index);
                              }}
                            >
                              <InlineIcon icon="material-symbols:manage-search-rounded" />
                            </IconButton>
                          </CustomTooltip>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
                {data.length === 0 && (
                  <div className="c-table-adherents-container__scroll-container__table-body__table-row">
                    <div className="row-item">Aucun résultat</div>
                  </div>
                )}
              </>
            )}
          </div>
        </PerfectScrollbar>
      </div>
      <SideModal open={open} onClose={handleClose} closeClick={handleClose}>
        <>
          <AdherentForm
            closeClick={handleClose}
            selectedAdherent={data[selectedIndex]}
          />
        </>
      </SideModal>
      <Dialog
        open={openRefuse}
        onClose={handleCloseRefuse}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Refus d'un adhérents"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de refuser les informations renseignés par{" "}
            <b>
              {data[selectedIndex]?.nom + " " + data[selectedIndex]?.prenoms}{" "}
            </b>{" "}
            .
            <br />
            <br /> <b>Êtes-vous sûr(e) de vouloir le faire ?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCloseRefuse}>
            Annuler
          </Button>
          <LoadingButton
            loading={loadingRefuse}
            loadingPosition="center"
            color="error"
            onClick={() => {
              refuseAdherent();
              handleCloseRefuse();
            }}
            autoFocus
          >
            Refuser
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAccept}
        onClose={handleCloseAccept}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Acceptation d'un adhérents"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point d'accorder l'accès à ce nouvel adhérent :
            <br />{" "}
            <b>
              {data[selectedIndex]?.nom + " " + data[selectedIndex]?.prenoms}{" "}
            </b>{" "}
            , dans votre réseau professionnel.
            <br />
            <br /> <b>Êtes-vous sûr(e) de vouloir le faire ?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCloseAccept}>
            Annuler
          </Button>
          <LoadingButton
            loading={loadingAccept}
            loadingPosition="center"
            color="primary"
            onClick={() => {
              acceptAdherent();
              handleCloseAccept();
            }}
            autoFocus
          >
            Accepter
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TableAdherentManager;
