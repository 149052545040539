import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectMember = (state: TRootState) => state.member;

export const selectUserConnected= createSelector(
  [selectMember],
  (member) => member.user_connected
);

export const selectToken= createSelector(
  [selectMember],
  (member) => member.token
);
