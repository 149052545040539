import { useRoutes } from "react-router-dom";

import { publicRoutes } from "./public.routes";
import { selectToken } from "@/store/reducers/member/member.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { protectedRoutes } from "./protected.routes";

type PropsFromRedux = ConnectedProps<typeof connector>;
const AppRoutesFct: React.FC<PropsFromRedux> = ({
  token
}) => {

  const routes = (token.length === 0) ? publicRoutes : protectedRoutes;
  const element = useRoutes([...routes]);

  return <>{element}</>;
};

const mapStateToProps = createStructuredSelector({
  token: selectToken,
});

const connector = connect(mapStateToProps);
const AppRoutes = connector(AppRoutesFct);

export default AppRoutes;
