import { createTheme, PaletteColorOptions } from "@mui/material";



declare module "@mui/material/styles" {
    interface Palette {
        tertiary: string;
    }  
    interface PaletteOptions {
        tertiary: PaletteColorOptions | undefined;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        // short: true;
        // long: true;
    }
    interface ButtonPropsColorOverrides {
        tertiary: true;
    }

}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
      tertiary: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
      tertiary: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; 
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true; 
  }
}

const { palette } = createTheme();

export const muiCustomTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400
    },
  },
  palette: {
    primary: {
      main: '#006C69',
      // dark:,
      // light:,
      //contrastText:,
    },
    secondary: {
      main: '#CEAD24',
      // dark:,
      // light:,
      contrastText: '#ffffff',
    },
    tertiary: palette.augmentColor({
        color: {
            main: '#009076',
            // dark:,
            // light:,
            contrastText: '#ffffff',
        }
    }),
    error: {
      main: '#872D2D',
      // dark:,
      // light:,
      //contrastText:,
    },
    warning: {
      main: '#F5B544',
      // dark:,
      // light:,
      //contrastText:,
    },
    // info: {
    //   main: '#73103E',
    //   dark:,
    //   light:,
    //   contrastText:,
    // },
    success: {
      main: '#75D873',
      // dark:,
      // light:,
      //contrastText:,
    },
  },
  components: {
    MuiTextField:{
      styleOverrides: {
        root: {
            width: "100%" ,
            height: '40px',
            marginBottom: 20,
          '& fieldset':{
            borderRadius: 5,
            borderColor: '#e6e8ec',
          }
        },
      },
    },
    MuiOutlinedInput:{
        styleOverrides: {
            root: {
                borderRadius: 5,
                borderColor: '#e6e8ec',
                '& fieldset':{
                    borderRadius: 5,
                    borderColor: '#e6e8ec',
                }
            }
        }
    },
    MuiButton:{
        styleOverrides:{
            root: {
                width: '100%',
                height: '50px',
            },
        },
        variants: [
            {
              props: { variant: 'contained' },
              style: {
                borderRadius: 15,
              },
            },
            {
                props: { variant: 'outlined' },
                style: {
                  borderRadius: 15,
                },
            }
        ]
    },
    MuiButtonBase:{
      styleOverrides:{
        root:{
            
        }
      }
    },
    MuiBottomNavigation:{
      styleOverrides:{
        root:{
          padding: '10px',
        }
      }
    },
    MuiBottomNavigationAction:{
      styleOverrides:{
        root:{
          color: '#F9F9F9',
          fontSize: '1.5rem',
          '&.Mui-selected': {
            color: "#009076",
            backgroundColor: "#F9F9F9",
          }
        }
      }
    }
  }
})
