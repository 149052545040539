import React from "react";
import "./DashboardListCard.styles.scss";
import { InlineIcon } from "@iconify/react";
import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import { TAdherent } from "@/features/profile/types";

type TDashboardListCardProps = {
  adherentsList: TAdherent[];
  loading: boolean;
};

const DashboardListCard: React.FC<TDashboardListCardProps> = ({
  adherentsList = [],
  loading,
}) => {
  const navigate = useNavigate();

  return (
    <div className="c-dashboard-list-container">
      <div className="title-part">
        <span className="title">Adhésions récentes</span>
        <Button
          color="primary"
          onClick={() => {
            navigate(routePaths.profileChildren.usersAbsolute, {
              replace: true,
            });
          }}
          endIcon={
            <InlineIcon icon="material-symbols:arrow-back-rounded" rotate={2} />
          }
        >
          Voir plus
        </Button>
      </div>
      {loading ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "20px",
            }}
          >
            <Skeleton
              variant="text"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="text"
              animation="pulse"
              width={"100%"}
              height={30}
            />
          </div>
        </>
      ) : (
        <List className="recent-list-part">
          {adherentsList.map((elt, index) => {
            return (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <InlineIcon icon="icon-park-solid:avatar" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={elt.nom} secondary={elt.poste} />
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      )}
    </div>
  );
};

export default DashboardListCard;
